// src/components/SearchAndFilters.js
import React from 'react';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import styled from '@emotion/styled';

const ControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  width: 400
}));

const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const SearchAndFilters = ({ search, handleSearchChange, filters, handleFilterChange }) => {
  return (
    <ControlsContainer>
      <SearchContainer>
        <TextField
          fullWidth
          variant="outlined"
          label="Search by Reference ID"
          size="small"
          value={search}
          onChange={handleSearchChange}
        />
      </SearchContainer>
      <FiltersContainer>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.pending}
              onChange={handleFilterChange}
              name="pending"
              color="primary"
            />
          }
          label="Pending"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.confirmed}
              onChange={handleFilterChange}
              name="confirmed"
              color="primary"
            />
          }
          label="Confirmed"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.rejected}
              onChange={handleFilterChange}
              name="rejected"
              color="primary"
            />
          }
          label="Rejected"
        />
      </FiltersContainer>
    </ControlsContainer>
  );
};

export default SearchAndFilters;
