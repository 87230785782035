
import axios from 'axios';

const uploadTransaction = async (baseUrl, file, apiKey) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${baseUrl}/api/wallet-transactions/upload`, formData, {
        headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};

export default uploadTransaction;
