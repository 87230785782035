const toggleWalletStatus = async (baseUrl, walletId, action, apiKey) => {
    const url = `${baseUrl}/api/wallets/${walletId}/${action}`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    });
  
    if (!response.ok) {
      throw new Error(`Failed to ${action} wallet`);
    }
  };
  
  export default toggleWalletStatus;
  