const addTransaction = async (baseUrl, newTransaction, apiKey) => {
    const url = `${baseUrl}/api/wallet-transactions`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify(newTransaction),
    });
  
    if (!response.ok) {
      throw new Error('Failed to add transaction');
    }
  };
  
  module.exports = addTransaction;
  