import React from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Switch
} from '@mui/material';
import { StyledTableContainer, StyledTable } from './CommonTableStyle';

const WalletTable = ({
  wallets,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  formatAmount,
  formatAccount,
  totalWallets,
  rowsPerPage,
  page,
  handleToggleStatus,
}) => {
  return (
    <StyledTableContainer>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Receive Limit Day</TableCell>
            <TableCell>Receive Limit Month</TableCell>
            <TableCell>Send Limit Day</TableCell>
            <TableCell>Send Limit Month</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wallets.map((wallet) => (
            <TableRow key={wallet.id}>
              <TableCell>{wallet.name}</TableCell>
              <TableCell>{formatAccount(wallet.accountNumber)}</TableCell>
              <TableCell>{wallet.accountName}</TableCell>
              <TableCell>{wallet.type}</TableCell>
              <TableCell>{formatAmount(wallet.receiveLimitDay)}</TableCell>
              <TableCell>{formatAmount(wallet.receiveLimitMonth)}</TableCell>
              <TableCell>{formatAmount(wallet.sendLimitDay)}</TableCell>
              <TableCell>{formatAmount(wallet.sendLimitMonth)}</TableCell>
              <TableCell>
                <Switch
                  checked={wallet.status === 'active'}
                  onChange={() => handleToggleStatus(wallet.id, wallet.status)}
                  color="primary"
                  disabled={loading}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalWallets}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTableContainer>
  );
};

export default WalletTable;