// src/components/RequestTable.js
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  TablePagination,
} from '@mui/material';
import styled from '@emotion/styled';

const TableWrapper = styled(Paper)(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
  backgroundColor: '#1e1e1e',
}));

const ButtonGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '8px',
}));

const LastUpdated = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginLeft: '16px',
  marginRight: '16px',
  fontSize: '0.875rem',
  color: 'rgba(255, 255, 255, 0.7)',
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  position: 'relative',
  display: 'inline-block',
}));

const RequestTable = ({
  requests,
  loading,
  getElapsedTime,
  formatAmount,
  formatAccount,
  handleChangePage,
  handleChangeRowsPerPage,
  totalRequests,
  rowsPerPage,
  page,
  lastUpdated,
  getLastUpdated,
  handleConfirmClick,
  handleRejectClick,
}) => {
  return (
    <TableWrapper>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>Elapsed</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Reference ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Customer ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Proof</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              requests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell title={request.createdAt}>{getElapsedTime(request.createdAt)}</TableCell>
                  <TableCell>{request.type}</TableCell>
                  <TableCell>{request.referenceId}</TableCell>
                  <TableCell>{formatAmount(request.amount)}</TableCell>
                  <TableCell>{formatAccount(request.customerAccountNumber)}</TableCell>
                  <TableCell>{request.customerId}</TableCell>
                  <TableCell>{request.customerName}</TableCell>
                  <TableCell>{request.status}</TableCell>
                  <TableCell>
                    <StyledLink
                      href={request.proofOfReceiptUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </StyledLink>
                  </TableCell>
                  <TableCell>
                    {request.status === 'pending' && (
                      <ButtonGroup>
                        <Button variant="contained" color="primary" size="small" disabled={loading} onClick={() => handleConfirmClick(request.id, request.amount)}>
                          Confirm
                        </Button>
                        <Button variant="outlined" color="primary" size="small" disabled={loading} onClick={() => handleRejectClick(request.id)}>
                          Reject
                        </Button>
                      </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <LastUpdated>{getLastUpdated()}</LastUpdated>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRequests}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </TableWrapper>
  );
};

export default RequestTable;
