import styled from '@emotion/styled';
import { TableContainer, Table } from '@mui/material';

const StyledTableContainer = styled(TableContainer)`
  background-color: #1e1e1e; /* Example color for dark theme */
  color: #ffffff;
`;

const StyledTable = styled(Table)`
  color: #ffffff;
`;

export { StyledTableContainer, StyledTable };
