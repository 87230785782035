import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, TextField, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const UploadTransactionModal = ({ open, handleClose, handleUploadTransaction }) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await handleUploadTransaction(file);
            setFile(null);
            handleClose();
        } catch (error) {
            console.error('Error uploading transaction:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setFile(null);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>
                Upload Transaction
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="file"
                    fullWidth
                    onChange={handleFileChange}
                    InputLabelProps={{ shrink: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!file || loading}
                    startIcon={loading && <CircularProgress size={20} />}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadTransactionModal;
