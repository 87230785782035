// src/utils/formatUtils.js
export const getElapsedTime = (timestamp) => {
  if (!timestamp) return '';

  const now = new Date();
  const then = new Date(timestamp);
  const seconds = Math.floor((now - then) / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = Math.floor(hours / 24);
  return `${days}d`;
};

export const formatAmount = (amount) => {
  return amount ? amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};

export const formatAccount = (account) => {
  if (!account) {
    return account;
  } else if (account.startsWith('0') && account.length === 11) {
    return `${account.slice(0, 4)}-${account.slice(4, 7)}-${account.slice(7)}`;
  } else if (account.startsWith('+63') && account.length === 12) {
    return `${account.slice(0, 3)}-${account.slice(3, 8)}-${account.slice(8)}`;
  } else {
    return account;
  }
};
