const fetchWalletTransactions = async (controller, baseUrl, page, rowsPerPage, search, apiKey) => {
  const url = new URL(`${baseUrl}/api/wallet-transactions`);
  url.searchParams.append('page', page + 1);
  url.searchParams.append('limit', rowsPerPage);
  url.searchParams.append('sortField', 'createdAt');
  url.searchParams.append('sortOrder', 'desc');
  url.searchParams.append('referenceId', search);
  url.searchParams.append('senderAccountNumber', search);
  url.searchParams.append('senderName', search);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
      signal: controller.signal,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch wallet transactions');
  }

  const data = await response.json();
  return {
    walletTransactions: data.walletTransactions,
    totalTransactions: data.totalTransactions,
  };
};

module.exports = fetchWalletTransactions;
