import React, { useState } from 'react';
import {
  Modal, Box, Typography, TextField, Button, MenuItem, Select, InputLabel, FormControl, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddRequestModal = ({ open, handleClose, fetchRequests }) => {
  const [referenceId, setReferenceId] = useState('');
  const [amount, setAmount] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerAccountNumber, setCustomerAccountNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [type, setType] = useState('');
  const [proofOfReceiptUrl, setProofOfReceiptUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    const data = {
      referenceId,
      amount: parseFloat(amount),
      customerId,
      customerAccountNumber,
      customerName,
      type: type.toLowerCase(),
      proofOfReceiptUrl,
    };

    setIsSubmitting(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/requests`, data, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TEST_API_KEY}`,
        },
      });
      handleClose();
      fetchRequests();
      // Clear fields after successful post
      setReferenceId('');
      setAmount('');
      setCustomerId('');
      setCustomerAccountNumber('');
      setCustomerName('');
      setType('');
      setProofOfReceiptUrl('');
    } catch (error) {
      console.error('Error creating request:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          Add Request
        </Typography>
        <TextField
          label="Reference ID"
          value={referenceId}
          onChange={(e) => setReferenceId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Customer ID"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Customer Account Number"
          value={customerAccountNumber}
          onChange={(e) => setCustomerAccountNumber(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Type"
          >
            <MenuItem value="Deposit">Deposit</MenuItem>
            <MenuItem value="Withdraw">Withdraw</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Proof of Receipt URL"
          value={proofOfReceiptUrl}
          onChange={(e) => setProofOfReceiptUrl(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default AddRequestModal;
