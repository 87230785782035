import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Button, Box } from '@mui/material';
import GlobalStyle from './globalStyles';
import SearchAndFilters from './components/SearchAndFilters';
import RequestTable from './components/RequestTable';
import LoadingProgress from './components/LoadingProgress';
import fetchRequests from './utils/fetchRequests';
import { getElapsedTime, formatAmount, formatAccount } from './utils/formatUtils';
import AddRequestModal from './components/AddRequestModal';
import ConfirmRequestModal from './components/ConfirmRequestModal';
import RejectRequestModal from './components/RejectRequestModal';
import confirmRequest from './utils/confirmRequest.js';
import rejectRequest from './utils/rejectRequest.js';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: '#121212',
  color: '#ffffff',
  minHeight: '100vh',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  padding: '0 20px',
}));

const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '20px',
}));

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const apiKey = process.env.REACT_APP_TEST_API_KEY;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const RequestsPage = () => {
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRequests, setTotalRequests] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({ pending: true, confirmed: false, rejected: false });
  const [lastUpdated, setLastUpdated] = useState(null);
  const abortControllerRef = useRef(null);
  const [open, setOpen] = useState(false);  
  const [confirmRequestModalOpen, setConfirmRequestModalOpen] = useState(false);
  const [rejectRequestModalOpen, setRejectRequestModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  
  useEffect(() => {
    document.title = 'Requests';
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, search, filters]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [page, rowsPerPage, search, filters]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLastUpdated(new Date(lastUpdated));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  const fetchData = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    setLoading(true);

    try {
      const data = await fetchRequests(controller, baseUrl, page, rowsPerPage, search, filters, apiKey);
      setRequests(data.requests);
      setTotalRequests(data.totalRequests);
      setLastUpdated(new Date());
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching requests:', error);
      }
    } finally {
      if (abortControllerRef.current === controller) {
        setLoading(false);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const getLastUpdated = () => {
    if (!lastUpdated) return '';
    const elapsedTime = getElapsedTime(lastUpdated);
    return `Last updated: ${elapsedTime} ago`;
  };

  const handleConfirmRequest = async (requestId, amount, responseNote) => {
    try {
      await confirmRequest(requestId, amount, responseNote);
    } catch (error) {
      console.error('Error confirming request:', error);
      setLoading(false);
    }
  };

  const handleRejectRequest = async (requestId, responseNote) => {
    try {
      await rejectRequest(requestId, responseNote);
    } catch (error) {
      console.error('Error rejecting request:', error);
      setLoading(false);
    }
  }

  const handleConfirmClick = (requestId, amount) => {
    setSelectedRequest({ id: requestId, amount });
    setConfirmRequestModalOpen(true);
  };

  const handleRejectClick = (requestId) => {
    setSelectedRequest({ id: requestId });
    setRejectRequestModalOpen(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <Root>
        <ContentContainer>
          <RowContainer>
            <SearchAndFilters
              search={search}
              handleSearchChange={handleSearchChange}
              filters={filters}
              handleFilterChange={handleFilterChange}
            />
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Add Request
            </Button>
          </RowContainer>
          <LoadingProgress loading={loading} />
          <AddRequestModal open={open} handleClose={handleClose} fetchRequests={fetchData} />
          <RequestTable
            requests={requests}
            loading={loading}
            getElapsedTime={getElapsedTime}
            formatAmount={formatAmount}
            formatAccount={formatAccount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalRequests={totalRequests}
            rowsPerPage={rowsPerPage}
            page={page}
            lastUpdated={lastUpdated}
            getLastUpdated={getLastUpdated}
            handleConfirmClick={handleConfirmClick}
            handleRejectClick={handleRejectClick}
          />
        </ContentContainer>
        {selectedRequest && (
          <ConfirmRequestModal
            open={confirmRequestModalOpen}
            handleClose={() => setConfirmRequestModalOpen(false)}
            handleConfirmRequest={handleConfirmRequest}
            fetchRequests={fetchData}
            amount={selectedRequest.amount}
            requestId={selectedRequest.id}
          />
        )}
        {selectedRequest && (
          <RejectRequestModal
            open={rejectRequestModalOpen}
            handleClose={() => setRejectRequestModalOpen(false)}
            handleRejectRequest={handleRejectRequest}
            fetchRequests={fetchData}
            requestId={selectedRequest.id}
          />
        )}
      </Root>
    </ThemeProvider>
  );
};

export default RequestsPage;
