// src/components/LoadingProgress.js
import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const LoadingProgress = ({ loading }) => {
  return loading ? (
    <Box width="100%">
      <LinearProgress />
    </Box>
  ) : null;
};

export default LoadingProgress;
