import React from 'react';
import { Box, TextField } from '@mui/material';

const SearchBar = ({ search, handleSearchChange }) => {
  return (
    <Box width={800}>
      <TextField
        fullWidth
        variant="outlined"
        label="Search by name, account number, account name..."
        size="small"
        value={search}
        onChange={handleSearchChange}
      />
    </Box>
  );
};

export default SearchBar;
