import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WalletsPage from './WalletsPage';
import RequestsPage from './RequestsPage';
import TransactionsPage from './TransactionsPage';

const App = () => (
  <Router>
    <Routes>
      <Route path="/wallets" element={<WalletsPage />} />
      <Route path="/requests" element={<RequestsPage />} />
      <Route path="/transactions" element={<TransactionsPage />} />
    </Routes>
  </Router>
);

export default App;
