// src/utils/fetchRequests.js
const fetchRequests = async (controller, baseUrl, page, rowsPerPage, search, filters, apiKey) => {
  const statusFilter = Object.keys(filters)
    .filter((key) => filters[key])
    .join(',');

  const response = await fetch(
    `${baseUrl}/api/requests?page=${page + 1}&limit=${rowsPerPage}&sortField=createdAt&sortOrder=desc&referenceId=${search}&status=${statusFilter}`,
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      signal: controller.signal,
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch requests');
  }

  const data = await response.json();
  return data;
};

export default fetchRequests;
