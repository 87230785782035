import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Button,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const RejectRequestModal = ({ open, handleClose, handleRejectRequest, fetchRequests, requestId}) => {
  const [responseNote, setResponseNote] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await handleRejectRequest(requestId, responseNote);
    await fetchRequests();

    setResponseNote('');

    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Reject Request
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Note"
          type="text"
          fullWidth
          value={responseNote}
          onChange={(e) => setResponseNote(e.target.value)}
        />
        <DialogActions>
          <SubmitButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </SubmitButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RejectRequestModal;
