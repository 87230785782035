import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box, Button } from '@mui/material';
import GlobalStyle from './globalStyles';
import WalletSearchBar from './components/WalletSearchBar';
import WalletTable from './components/WalletTable';
import LoadingProgress from './components/LoadingProgress';
import fetchWallets from './utils/fetchWallets';
import { formatAmount, formatAccount } from './utils/formatUtils';
import AddWalletModal from './components/AddWalletModal';
import toggleWalletStatus from './utils/toggleWalletStatus';
import uploadTransaction from './utils/uploadTransaction';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: '#121212',
  color: '#ffffff',
  minHeight: '100vh',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  padding: '0 20px',
}));

const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '20px',
}));

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const apiKey = process.env.REACT_APP_TEST_API_KEY;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const WalletsPage = () => {
  const [wallets, setWallets] = useState([]);
  const [walletPage, setWalletPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalWallets, setTotalWallets] = useState(0);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [searchWallets, setSearchWallets] = useState('');
  const [addWalletModalOpen, setAddWalletModalOpen] = useState(false);
  const abortControllerRef = useRef({ wallets: null });
  const [uploadTransactionModalOpen, setUploadTransactionModalOpen] = useState(false);

  useEffect(() => {
    document.title = 'Wallets';
  }, []);

  useEffect(() => {
    fetchData();
  }, [walletPage, rowsPerPage, searchWallets]);

  const fetchData = async () => {
    if (abortControllerRef.current.wallets) {
      abortControllerRef.current.wallets.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current.wallets = controller;

    setLoadingWallets(true);

    try {
      const data = await fetchWallets(controller, baseUrl, walletPage, rowsPerPage, searchWallets, apiKey);
      setWallets(data.wallets);
      setTotalWallets(data.totalWallets);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching wallets:', error);
      }
    } finally {
      if (abortControllerRef.current.wallets === controller) {
        setLoadingWallets(false);
      }
    }
  };

  const handleWalletChangePage = (event, newPage) => {
    setWalletPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setWalletPage(0);
  };

  const handleSearchWalletsChange = (event) => {
    setSearchWallets(event.target.value);
  };

  const handleAddWallet = async (newWallet) => {
    try {
      const response = await fetch(`${baseUrl}/api/wallets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(newWallet),
      });

      if (!response.ok) {
        throw new Error('Failed to add wallet');
      }

      fetchData();
    } catch (error) {
      console.error('Error adding wallet:', error);
    }
  };

  const handleToggleStatus = async (walletId, currentStatus) => {
    const action = currentStatus === 'active' ? 'deactivate' : 'activate';

    setLoadingWallets(true);

    try {
      await toggleWalletStatus(baseUrl, walletId, action, apiKey);
      fetchData();
    } catch (error) {
      console.error(`Error toggling wallet status:`, error);
    } finally {
      setLoadingWallets(false);
    }
  };    
  
  const handleUploadTransaction = async (file) => {
    try {
        await uploadTransaction(baseUrl, file, apiKey);
        fetchData();
    } catch (error) {
        console.error('Error uploading transaction:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <Root>
        <ContentContainer>
          <RowContainer>
            <WalletSearchBar
              search={searchWallets}
              handleSearchChange={handleSearchWalletsChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddWalletModalOpen(true)}
            >
              Add Wallet
            </Button>
          </RowContainer>
          <LoadingProgress loading={loadingWallets} />
          <WalletTable
            wallets={wallets}
            loading={loadingWallets}
            handleChangePage={handleWalletChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            formatAmount={formatAmount}
            formatAccount={formatAccount}
            totalWallets={totalWallets}
            rowsPerPage={rowsPerPage}
            page={walletPage}
            handleToggleStatus={handleToggleStatus}
          />
        </ContentContainer>
        <AddWalletModal
          open={addWalletModalOpen}
          handleClose={() => setAddWalletModalOpen(false)}
          handleAddWallet={handleAddWallet}
        />
      </Root>
    </ThemeProvider>
  );
};

export default WalletsPage;
