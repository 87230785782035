import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box, Button, Typography } from '@mui/material';
import GlobalStyle from './globalStyles';
import WalletTransactionsSearchBar from './components/WalletTransactionsSearchBar';
import WalletTransactionsTable from './components/WalletTransactionsTable';
import LoadingProgress from './components/LoadingProgress';
import fetchWalletTransactions from './utils/fetchWalletTransactions';
import { getElapsedTime, formatAmount, formatAccount } from './utils/formatUtils';
import AddTransactionModal from './components/AddTransactionModal';
import UploadTransactionModal from './components/UploadTransactionModal';
import addTransaction from './utils/addTransaction';
import uploadTransaction from './utils/uploadTransaction';
import styled from '@emotion/styled';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: '#121212',
  color: '#ffffff',
  minHeight: '100vh',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  padding: '0 20px',
}));

const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '20px',
}));

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const apiKey = process.env.REACT_APP_TEST_API_KEY;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const TransactionsPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [searchTransactions, setSearchTransactions] = useState('');
  const abortControllerRef = useRef({ transactions: null });
  const [lastUpdated, setLastUpdated] = useState(null);
  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false);
  const [uploadTransactionModalOpen, setUploadTransactionModalOpen] = useState(false);

  useEffect(() => {
    document.title = 'Transactions';
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [transactionPage, rowsPerPage, searchTransactions]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchTransactions();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [transactionPage, rowsPerPage, searchTransactions]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (lastUpdated) {
        setLastUpdated(new Date(lastUpdated));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  const getLastUpdated = () => {
    if (!lastUpdated) return '';
    const elapsedTime = getElapsedTime(lastUpdated);
    return `Last updated: ${elapsedTime} ago`;
  };

  const fetchTransactions = async () => {
    if (abortControllerRef.current.transactions) {
      abortControllerRef.current.transactions.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current.transactions = controller;

    setLoadingTransactions(true);

    try {
      const data = await fetchWalletTransactions(controller, baseUrl, transactionPage, rowsPerPage, searchTransactions, apiKey);
      setTransactions(data.walletTransactions);
      setTotalTransactions(data.totalTransactions);
      setLastUpdated(new Date());
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching wallet transactions:', error);
      }
    } finally {
      if (abortControllerRef.current.transactions === controller) {
        setLoadingTransactions(false);
      }
    }
  };

  const handleTransactionChangePage = (event, newPage) => {
    setTransactionPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTransactionPage(0);
  };

  const handleSearchTransactionsChange = (event) => {
    setSearchTransactions(event.target.value);
  };

  const handleAddTransaction = async (newTransaction) => {
    try {
      await addTransaction(baseUrl, newTransaction, apiKey);
      fetchTransactions();
    } catch (error) {
      console.error('Error adding transaction:', error);
    }
  };

  const handleUploadTransaction = async (file) => {
    try {
        await uploadTransaction(baseUrl, file, apiKey);
        fetchTransactions();
    } catch (error) {
        console.error('Error uploading transaction:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <Root>
        <ContentContainer>
          <RowContainer>
            <WalletTransactionsSearchBar
              search={searchTransactions}
              handleSearchChange={handleSearchTransactionsChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUploadTransactionModalOpen(true)}
            >
              Upload
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddTransactionModalOpen(true)}
            >
              Add Transaction
            </Button>
          </RowContainer>
          <LoadingProgress loading={loadingTransactions} />
          <WalletTransactionsTable
            transactions={transactions}
            loading={loadingTransactions}
            handleChangePage={handleTransactionChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            formatAmount={formatAmount}
            formatAccount={formatAccount}
            totalTransactions={totalTransactions}
            rowsPerPage={rowsPerPage}
            page={transactionPage}
            getLastUpdated={getLastUpdated}
          />
        </ContentContainer>
        <AddTransactionModal
          open={addTransactionModalOpen}
          handleClose={() => setAddTransactionModalOpen(false)}
          handleAddTransaction={handleAddTransaction}
        />
        <UploadTransactionModal
          open={uploadTransactionModalOpen}
          handleClose={() => setUploadTransactionModalOpen(false)}
          handleUploadTransaction={handleUploadTransaction}
        />
      </Root>
    </ThemeProvider>
  );
};

export default TransactionsPage;
