const confirmRequest = async(requestId, amount, responseNote) => { 
    const apiKey = process.env.REACT_APP_TEST_API_KEY;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = new URL(`${baseUrl}/api/requests/${requestId}/confirm`);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify(
            {
                requestId: requestId,
                amount: amount,
                responseNote: responseNote
            }
        ),
    });

    if (response.ok) {
        return response.json();
    } else {
        throw new Error('Error confirming request');
    }
}

export default confirmRequest;