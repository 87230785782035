import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Button, // Add this line
  DialogActions // Add this line
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const AddWalletModal = ({ open, handleClose, handleAddWallet }) => {
  const [name, setName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [receiveLimitDay, setReceiveLimitDay] = useState('');
  const [receiveLimitMonth, setReceiveLimitMonth] = useState('');
  const [sendLimitDay, setSendLimitDay] = useState('');
  const [sendLimitMonth, setSendLimitMonth] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const newWallet = {
      name,
      accountName,
      accountNumber,
      receiveLimitDay: parseInt(receiveLimitDay, 10),
      receiveLimitMonth: parseInt(receiveLimitMonth, 10),
      sendLimitDay: parseInt(sendLimitDay, 10),
      sendLimitMonth: parseInt(sendLimitMonth, 10),
      type: 'gcash'
    };
    await handleAddWallet(newWallet);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Add Wallet
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Wallet Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Account Name"
          type="text"
          fullWidth
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Account Number"
          type="text"
          fullWidth
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Receive Limit Day"
          type="number"
          fullWidth
          value={receiveLimitDay}
          onChange={(e) => setReceiveLimitDay(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Receive Limit Month"
          type="number"
          fullWidth
          value={receiveLimitMonth}
          onChange={(e) => setReceiveLimitMonth(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Send Limit Day"
          type="number"
          fullWidth
          value={sendLimitDay}
          onChange={(e) => setSendLimitDay(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Send Limit Month"
          type="number"
          fullWidth
          value={sendLimitMonth}
          onChange={(e) => setSendLimitMonth(e.target.value)}
        />
        <DialogActions>
          <SubmitButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </SubmitButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddWalletModal;
