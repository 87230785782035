const fetchWallets = async (controller, baseUrl, page, rowsPerPage, search, apiKey) => {
  const url = new URL(`${baseUrl}/api/wallets`);
  url.searchParams.append('page', page + 1);
  url.searchParams.append('limit', rowsPerPage);
  url.searchParams.append('sortField', 'createdAt');
  url.searchParams.append('sortOrder', 'desc');

  if (search) {
    url.searchParams.append('name', search);
    url.searchParams.append('accountNumber', search);
    url.searchParams.append('accountName', search);
  }

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${apiKey}`,
    },
    signal: controller.signal,
  });

  if (!response.ok) {
    throw new Error('Failed to fetch wallets');
  }

  const data = await response.json();
  return data;
};

export default fetchWallets;
