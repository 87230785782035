import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Modal, Box, Typography, IconButton, CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { formatAmount, formatAccount } from '../utils/formatUtils';
import CloseIcon from '@mui/icons-material/Close';

const StyledLink = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  position: 'relative',
  display: 'inline-block',
}));

const PreviewImage = styled('img')({
  display: 'none',
  position: 'fixed',
  maxWidth: '800px',
  maxHeight: '800px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  zIndex: 1000,
  backgroundColor: '#fff',
});

const StyledLinkContainer = styled('div')({
  position: 'relative',
  '&:hover img': {
    display: 'block',
  },
});

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  maxWidth: '90%',
  maxHeight: '90%',
  overflow: 'auto',
}));

const Image = styled('img')({
  maxWidth: '100%',
  maxHeight: '80vh',
  marginTop: 20,
});

const LastUpdated = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginLeft: '16px',
  marginRight: '16px',
  fontSize: '0.875rem',
  color: 'rgba(255, 255, 255, 0.7)',
}));

const WalletTransactionsTable = ({
  transactions,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  totalTransactions,
  rowsPerPage,
  page,
  getLastUpdated,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const handleOpen = (transaction) => {
    setSelectedTransaction(transaction);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTransaction(null);
    setOpen(false);
  };

  const handleMouseMove = (e) => {
    const previewImage = document.getElementById('preview-image');
    if (previewImage) {
      previewImage.style.top = `${e.clientY + 20}px`;
      previewImage.style.left = `${e.clientX + 20}px`;
    }
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Reference ID</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Sender Account</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Screenshot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{new Date(transaction.createdAt).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}</TableCell>
                <TableCell>{formatAmount(transaction.amount)}</TableCell>
                <TableCell>{transaction.referenceId}</TableCell>
                <TableCell>{transaction.senderName}</TableCell>
                <TableCell>{formatAccount(transaction.senderAccountNumber)}</TableCell>
                <TableCell>{transaction.status}</TableCell>
                <TableCell onMouseMove={handleMouseMove}>
                  {transaction.screenshotUrl ? (
                    <StyledLinkContainer>
                      <StyledLink onClick={() => handleOpen(transaction)}>
                        View
                      </StyledLink>
                      <PreviewImage id="preview-image" src={transaction.screenshotUrl} alt="preview" />
                    </StyledLinkContainer>
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <LastUpdated>{getLastUpdated()}</LastUpdated>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalTransactions}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedTransaction && (
            <>
              <Typography variant="h6" component="h2">
                {selectedTransaction.referenceId}
              </Typography>
              <Image
                src={selectedTransaction.screenshotUrl}
                alt={selectedTransaction.referenceId}
              />
            </>
          )}
        </ModalContent>
      </Modal>
    </Paper>
  );
};

export default WalletTransactionsTable;
